var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content content-full content-boxed"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errorAction.update,
      expression: "errorAction.update"
    }],
    staticClass: "alert alert-danger"
  }, [_vm._v(_vm._s(_vm.errorAction.update))]), _c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content"
  }, [_c('h3', [_vm._v("Edit Supplier")]), _c('SupplierForm', {
    attrs: {
      "error": _vm.errorAction.update || {}
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c('div', {
    staticClass: "text-right pb-4"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit",
      "disabled": _vm.loadingAction.update
    },
    on: {
      "click": _vm.onUpdateSupplier
    }
  }, [_vm.loadingAction.update ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" Updating...")]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Update Supplier")])])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }